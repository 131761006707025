/* eslint-disable no-new */
import React from 'react'
import { graphql } from 'gatsby'
import ContentRender from '../../ContentRender'

interface ParagraphCustomHTMLProps {
  field_html: {
    value: string
  }
}

export const ParagraphCustomHtmlFields = graphql`
  fragment ParagraphCustomHtmlFields on paragraph__custom_html {
    __typename
    id
    field_html {
      value
    }
  }
`

const ParagraphCustomHTML = ({ field_html }: ParagraphCustomHTMLProps) => (
  <>
    <ContentRender>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: field_html?.value }} />
    </ContentRender>
  </>
)

export default ParagraphCustomHTML
